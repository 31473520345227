import React from "react";
import Theme from "./theme";
import { Link } from "gatsby";
import { RiInstagramFill } from "react-icons/ri";

const MenuItems = [
  {
    path: "/work",
    title: "Work",
    partiallyActive: true,
  },
  {
    path: "/info",
    title: "Info",
    partiallyActive: false,
  },
  {
    path: "/blog",
    title: "News",
  },
];

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }));
  }

  render() {
    const listMenuItems = MenuItems.map(
      ({ path, partiallyActive, title }, index) => (
        <li key={index} className="site-nav__list-item">
          <Link
            to={path}
            className="site-nav__list-item--link"
            partiallyActive={partiallyActive}
            activeClassName="link-active"
          >
            {title}
          </Link>
        </li>
      )
    );

    return (
      <nav className="site-nav">
        <ul className="site-nav__list">
          {listMenuItems}
          <li className="site-nav__list-item site-nav__list-item--icon">
            <a
              href={`https://www.instagram.com/fiona.m.chambers/`}
              className="site-nav__list-item--link"
              target="_blank"
              rel="noreferrer"
            >
              <RiInstagramFill />
            </a>
          </li>
          <li className="site-nav__list-item site-nav__list-item--button">
            <Theme />
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
