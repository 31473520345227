import React, { Fragment } from "react";
import useLocalStorageState from "use-local-storage-state";
import { Helmet } from "react-helmet";
import { FiMoon } from "react-icons/fi";
import { FiSun } from "react-icons/fi";

const Theme = () => {
  const [colorMode, setColorMode] = useLocalStorageState(
    "theme-color-mode",
    "default"
  );

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          class: colorMode === "default" ? "default" : "dark",
        }}
      >
        <meta
          name="theme-color"
          content={colorMode === "default" ? "#ffffff" : "#0f0f0f"}
        ></meta>
      </Helmet>

      <button
        className="site-nav__list-button"
        onClick={_e => {
          setColorMode(colorMode === "default" ? "dark" : "default");
        }}
      >
        {colorMode === "default" ? <FiMoon /> : <FiSun />}
      </button>
    </Fragment>
  );
};
export default Theme;
